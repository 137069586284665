<template>
  <div class="page-box s-page">
    <div class="s-header">
      <van-nav-bar
        :title="$t('mining.title')"
        left-arrow
        @click-left="$router.go(-1)"></van-nav-bar>
    </div>
    <div class="maincontent s-content">
        <div class="funds">
            <div>
                <div class="title">{{$t('mining.funds')}}</div>
                <div class="money">
                    <span>{{userdata.total}}</span>
                    <span style="font-size: 0.81rem;padding-left: 0.34rem;">USD</span>
                </div>
            </div>
            <div class="locked" @click="$router.push('/subscribeList')">
              <van-icon name="notes-o" color="#004EA3" />
              <div class="text">{{$t('mining.locked-order')}}</div>
            </div>
        </div>
        <div class="fundsList">
          <van-row gutter="5">
            <van-col span="8">
              <div class="value">{{userdata.today_waiting}}</div>
              <div class="title">
                <span>{{$t('mining.expected-earnings-today')}}</span>
                <van-icon name="question-o"  @click="show = true" />
              </div>
            </van-col>
            <van-col span="8">
              <div class="value">{{userdata.total_income}}</div>
              <div class="title">{{$t('mining.cumulative-income')}}</div>
            </van-col>
            <van-col span="8">
              <div class="value">{{userdata.total_orders}}</div>
              <div class="title">{{$t('mining.order-in-escrow')}}</div>
            </van-col>
          </van-row>
        </div>
        <div>
            <div class="prod-item" v-for="(item, key) in list" :key="key">
                <div class="item-top">
                    <div class="title">USD(USDT){{$t('mining.lock-up')}}/{{item.times}}(day)</div>
                    <div class="lock-btn" @click="$router.push('/subscribemining?id='+item.id)">{{$t('mining.lock-up')}}</div>
                </div>
                <div class="row-flex">
                    <div>
                        <div class="t1">{{$t('mining.minimum-single')}}</div>
                        <div class="t2">{{item.low_money}}</div>
                    </div>
                    <div>
                        <div class="t1">{{$t('mining.single-most')}}</div>
                        <div class="t2">{{item.max_money=='-1'?$t('mining.unlimited'):item.max_money}}</div>
                    </div>
                    <div>
                        <div class="t1">{{$t('mining.daily-rate-of-return')}}</div>
                        <div class="t2">{{item.daily_rate_min}}-{{item.daily_rate_max}}%</div>
                    </div>
                    <div style="text-align: right;">
                        <div style="color: #8796A5;">{{$t('mining.lock-up-period')}}</div>
                        <div class="t2">{{item.times}}(day)</div>
                    </div>
                </div>
            </div>
            <div style="font-size: 0.81rem;color: #4B8EFF;text-align: center;padding-top: 1.88rem;">{{$t('mining.no-more')}}</div>
        </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <div class="block-title">{{$t('mining.explain')}}</div>
          <div class="block-content">
            {{$t('mining.explain1')}} <br/>
            {{$t('mining.explain2')}} <br/>
            {{$t('mining.explain3')}}
          </div>
          <div  @click="show = false" class="block-close">{{$t('mining.got-it')}}</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      userdata: {
        total: 0
      },
      show: false
    }
  },
  created() {
    this.getdata()
  },
  methods: {
    //
    async getdata() {
      const { data } = await this.$http.get('/home/miner/infolist')
      this.list = data.data.list
      this.userdata = data.data.userdata
      console.log('data', this.list)
    }
  }
}
</script>
<style lang="less" scoped>
  .maincontent {
    .funds{
      background: #004EA3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.40625rem 0 1.875rem 1.5625rem;
      text-align: left;
      .title {
        font-weight: 400;
        font-size: 0.875rem;
        color: #FBFCF9;
        margin-bottom: 0.625rem;
      }
      .money{
        font-weight: 500;
        font-size: 1.125rem;
        color: #FBFCF9;
      }
      .locked {
        display: flex;
        align-items: center;
        background: #f2f5fd;
        border-radius: 3.125rem 0 0 3.125rem;
        padding: 0.625rem;
        .text {
          font-weight: 500;
          font-size: 0.75rem;
          color: #004EA3;
          padding-left: 0.34375rem;
        }
      }
    }
    .fundsList{
      background-color: #4DA2FF;
      padding: 0.75rem 1rem;
      color: #FBFCF9;
      .value{
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        margin-bottom: 0.59rem;
      }
      .title{
        font-weight: 400;
        font-size: 0.875rem;
        color: #FBFCF9;
        .van-icon{
          vertical-align: middle;
          margin-left: 0.3125rem;
        }
      }
    }
  }
  .prod-item{
    padding: 0.63rem 0.94rem;
    border-bottom: 0.06rem solid #EFF1F3;
    .item-top {
        display: flex;justify-content: space-between;align-items: center;margin-bottom: 1rem;
    }
    .title{
        font-size: 0.81rem;
        color: #333848;
    }
    .lock-btn{
        width: 5.06rem;
        height: 1.75rem;
        line-height: 1.75rem;
        text-align: center;
        background: #325DE3;
        border-radius: 0.19rem;
        color: #fff;
        font-size: 0.81rem;
    }
    .row-flex{
        display: flex;
        >div{
            flex: 1;
            text-align: left;
            font-size: 0.69rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .t1{
            color: #8796A5;
            max-width: 5rem;
        }
        .t2{
            color: #333848;
            margin-top: 0.75rem;
        }
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 20rem;
    background: #FFFFFF;
    border-radius: 0.38rem;
    opacity: 1;
    padding: 1.25rem 1.16rem;
    box-sizing: border-box;
    .block-title{
        text-align: left;
        font-size: 1.13rem;
        color: #333333;
        margin-bottom: 0.72rem;
    }
    .block-content{
        font-size: 0.81rem;
        color: #333848;
        line-height: 1.19rem;
        border-bottom: 0.13rem solid #C0C0C0;
        padding-bottom: 0.94rem;
        text-align: left;
    }
    .block-close{
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        color: #fff;
        background: #325DE3;
        border-radius: 0.19rem;
        margin-top: 1.25rem;
    }
  }
</style>
